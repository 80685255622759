<script>
import { isObjectEmpty } from '@/shared/utils/globalMethods';

export default {
  name: 'ConversationCard',
  props: {
    conversationInfo: {
      type: Object,
    },
    notRendered: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    conversationSelected: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Set a custom css class to the button
    customClass: String,
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: {
      type: Object,
    },
    // Defines a callback function which can be used to update parent state on change event
    callback2: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction2: String,
    // Defines params for callback function or callback action
    callbackParams2: {
      type: Object,
    },
    clickCard: Boolean,
    spinner: {
      type: Boolean,
      default: false,
    },
    to: String,
  },
  methods: {
    clickCallback() {
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }
      }
    },
    clickCallback2() {
      if (this.callback2) {
        if (!isObjectEmpty(this.callbackParams2)) {
          this.callback2(this.callbackParams2);
        } else {
          this.callback2();
        }
      }
    },
  },
};
</script>
<template>
  <div class="container-card-category">
    <b-row>
      <b-col md="10" v-on:click="clickCallback">
        <div :class="'headline-bold-16 basic-2 ' +
        (conversationSelected === conversationInfo.id ? 'purple' : 'black')"
             style="text-overflow: ellipsis;overflow: hidden;margin-top: 4px">
          <div :class="'ellipse ' +
          (conversationSelected === conversationInfo.id ? 'purple' : 'black')">
          </div>
          {{ conversationInfo.name && conversationInfo.name.length > 20 ?
          (conversationInfo.name.slice(0,20) + '...')
          : conversationInfo.name }}
        </div>
      </b-col>
      <b-col md="2">
        <div v-if="clickCard" class="edit-category" v-on:click="clickCallback2">
          <unicon name="trash-alt" fill="#333333" style="height: 16px"
                  height="16" width="16"/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
.container-card-category{
  background: #FFFFFF;
  border-bottom: 1px solid #E0E0E0;
  padding: 16px 0;
}
.container-card-category .title-1-bold-4{
  vertical-align: middle;
  display: flex;
}
.container-card-category .ellipse{
  width: 4px;
  height: 4px;
  margin-right: 6px;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}
.container-card-category .ellipse.black {
  background: #333333;

}
.container-card-category .ellipse.purple {
  background: #1694D0;

}
.container-card-category .headline-bold-16.black {
  color: #333333;

}
.container-card-category .headline-bold-16.purple {
  color: #1694D0;

}
.container-card-category .icon-container{
  height: 38px;
  width: 38px;
  justify-content: center;
  vertical-align: center;
  display: inline-flex;
  align-items: center;
  background: #F2F2F2;
  border-radius: 8px;
}
.container-card-category .edit-category {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  height: 32px;
  width: 32px;
  padding: 8px;
  cursor: pointer;
  float: right;
}
.container-card-category .edit-category:hover,.container-card-category .edit-category:active {
  background: rgba(255, 255, 255, 1);
}
.container-card-category .edit-category svg{
  height: 16px;
  width: 16px;
  margin-top: -8px;
}
</style>
