<script>
import Recorder from '@/shared/components/Recorder.vue';
import BaseButton from '@/shared/components/BaseButton.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import ConversationCard from '@/shared/cards/ConversationCard.vue';
import AddCard from '@/shared/components/AddCard.vue';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);

const store = {
  state: {
    conversations: [],
    conversationId: 0,
    conversation: null,
    messages: [],
    tagId: 0,
    tagTitleForCard: '',
    loading: false,
    itemCard: null,
    scroll: 0,
    messages_remain: 0,
    messages_used: 0,
  },
};
export default {
  components: {
    ConversationCard,
    BaseButton,
    AddCard,
    Recorder,
  },
  data() {
    return {
      message: '',
      store,
      file: '',
      model: {},
      filesLibrary: [],
      droppedLibrary: 0,
    };
  },
  methods: {
    getLimits() {
      const listConversationsPath = '/WebGetMessagesLimit.php';
      fetch(server + listConversationsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          store.state.messages_remain = json.messages_remain;
          store.state.messages_used = json.messages_used;
        });
    },
    ListConversations() {
      store.state.conversations = [];
      const listConversationsPath = '/WebOpenaiListConversations.php';
      fetch(server + listConversationsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.conversations && json.conversations.length) {
            store.state.conversationId = json.conversations[0].id;
            [store.state.conversation] = json.conversations;
            this.ListMessages(json.conversations[0].id);

            store.state.conversations = json.conversations;
          }
        });
    },
    ListMessages(id) {
      store.state.messages = [];
      const listTagsPath = '/WebOpenaiListConversationMessages.php';
      const data = new FormData();
      data.append('search', '');
      data.append('conversation_id', id);
      fetch(server + listTagsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            }
          }
          if (json.messages.length > 0 && json.messages[json.messages.length - 1].role === 'user') {
            setTimeout(() => {
              this.ListMessages(json.conversation_id);
            }, 3000);
          } else {
            if (json.messages && json.messages.length) {
              json.messages.forEach((item) => {
                this.store.state.messages.push(item);
              });
              this.getLimits();
            } else {
              store.state.messages = [];
            }
            store.state.scroll += 1;
            const el = document.getElementById('scroll-to-me');
            el.scrollIntoView({ behavior: 'smooth' });
            store.state.loading = false;
          }
        });
    },
    setConversation(conversation) {
      store.state.conversation = conversation;
      store.state.conversationId = conversation ? conversation.id : 0;
      store.state.messages = [];
      if (conversation !== 0) {
        this.ListMessages(conversation.id);
      }
    },
    addMessage() {
      const cs = document.getElementById('container-scroll');
      cs.scrollTo({
        top: document.getElementById('scroll-to-me').offsetTop,
        left: 0,
        behavior: 'smooth',
      });
      store.state.scroll += 1;
      store.state.loading = true;
      const addCatPath = '/WebOpenaiSendMessage.php';
      const data = new FormData();
      data.append('message', this.message);
      data.append('conversation_id', store.state.conversationId);
      fetch(server + addCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
          AcceptLanguage: window.localStorage.getItem('language'),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          store.state.conversationId = json.conversation_id;
          this.message = '';

          setTimeout(() => {
            this.ListMessages(json.conversation_id);
          }, 7000);
        }
      });
    },
    openAddCart() {
      const addCardWindow = document.querySelector('.view-item');
      addCardWindow.classList.toggle('show');
      if (addCardWindow.classList.value.includes('hide')) {
        addCardWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addCardWindow.classList.toggle('hide');
        }, 300);
      }
    },
    addCard(answer, question) {
      setTimeout(() => {
        store.state.itemCard = {
          question,
          answer,
        };
      }, 300);
      this.openAddCart();
    },
    deleteConversation(conversation) {
      const deletePath = '/WebOpenaiRemoveConversation.php';
      const data = new FormData();
      data.append('conversation_id', conversation.id);
      fetch(server + deletePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          this.ListConversations();
        }
      });
    },
  },
  beforeMount() {
    this.ListConversations();
  },
};
</script>
<template lang="html">
  <div>
    <b-row class="chat">
      <b-col md="12" lg="12" style="margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: inline-flex;align-items: center;">
          {{ $t("CHAT.MAIN_TITLE") }}
        </div>
        <div style="display: inline-flex">
          ({{ $t("CHAT.MESSAGES_REMAIN") }}:
          {{store.state.messages_remain - store.state.messages_used}})</div>
      </b-col>
    </b-row>
    <b-row style="margin: 0 -12.5px!important;">
      <b-col md="3" sm="12">
        <div class="card" style="padding: 16px;height: calc(100vh - 220px)">
          <div v-on:click="setConversation(0)"
               style="width: -webkit-fill-available;margin-bottom: 16px">
            <BaseButton style="width: -webkit-fill-available;">
              {{ $t('CHAT.START_NEW_CHAT') }}
            </BaseButton>
          </div>
          <div style="height: calc(100vh - 268px); overflow-y: scroll">

            <div v-for="(item,i) in store.state.conversations" :key="i">
              <ConversationCard
                :conversation-info="item"
                :callback="setConversation"
                :callback-params="item"
                :callback2="deleteConversation"
                :callback-params2="item"
                :click-card="true"
                :conversation-selected="store.state.conversationId"
              >
              </ConversationCard>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="9" sm="12">

        <div class="card" style="padding: 16px;height: calc(100vh - 220px)">
          <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px;
          justify-content: center">
            <div class="title-0-medium-28 basic-1"
                 style="margin-right: 40px;display: flex;align-items: center; text-align: center">
              {{ $t("CHAT.TITLE") }} - {{
                store.state.conversation ?
                  (store.state.conversation && store.state.conversation.name.length > 20 ?
                    (store.state.conversation.name.slice(0, 20) + '...')
                    : store.state.conversation.name) : $t("CHAT.NEW_CHAT")
              }}
            </div>
          </div>
          <div id="container-scroll" style="height: calc(100vh - 379px); overflow-y: scroll"
               v-chat-scroll="{always: false, smooth: true}" :key="store.state.scroll">

            <div>
              <div v-for="(item, i) in store.state.messages" :key="item.id"
                   :class="'message-' + item.role"
              >

                <div style="display: flex">
                  <div style="display: inline-flex;width: 24px;height: 24px;margin-right: 16px">
                    <div v-if="item.role === 'user'"
                         style="background: #FDAB3D;border-radius: 50%; color: white;width: 24px;
                      height: 24px;display: flex; justify-content: center;align-items: center;
                      ">
                      ?
                    </div>

                    <div v-if="item.role === 'assistant'"
                         style="width: 100%;height: 100%">
                      <img style="width: 24px" src="/media/gpt.svg"/>
                    </div>
                  </div>
                  <div style="display: inline-flex">
                    {{ item.message }}
                  </div>
                  <div class="d-inline-flex" v-if="item.role === 'assistant'">
                    <Button class="btn-primary" style="padding: 6px!important;
                    height: 30px!important;margin-left: 16px"
                    v-on:click="addCard(item, store.state.messages[i-1])"
                    >
                      <img class="d-inline-block"
                           style="margin-right: 0; height: 18px; width: 18px"
                           src="/media/u_image-plus.svg" alt=""/>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="store.state.loading === true" style="display: flex;
               justify-content: center">

              <unicon fill="#333333" height="24" width="24" name="spinner-alt"/>

            </div>
            <div id="scroll-to-me">
            </div>
          </div>
          <b-row style="position: absolute; bottom: 17px; left: 17px; width: calc(100% - 34px)">
            <b-col sm="12" style="padding: 0!important;">

              <form class="form" v-on:submit.prevent="addMessage(1)"
                    id="add_dep_form" style="width: 100%;">
                <div class="custom-input recorder">
                <input type="text"
                       class="form-control-2"
                       v-model="message"
                       :placeholder="$t('CHAT.TYPE_HERE')"
                       required
                       maxlength="200"
                >
                <Recorder
                    :onChange="(value,rec) => {
                          message = value
                        }"
                />
                </div>
                <BaseButton
                  style="position: absolute; right: 60px; bottom: 10px;background: transparent;
                padding: 0;border: 0"
                  class="float-end"
                  :variant="'link'"
                  type="submit">
                  <unicon fill="#FDAB3D" height="24" width="24" name="arrow-right"/>
                </BaseButton>
              </form>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <div class="view-item hide">
      <AddCard :key="store.state.itemCard && store.state.itemCard.question ?
       store.state.itemCard.question.id : 0"
               :info="store.state.itemCard"
               :tag-title="''"
               :card-id="0"
               :callback="() => {}"
               :show-ai-method="false"
               :with-ai="false"
      >
      </AddCard>
    </div>
  </div>
</template>
<style lang="scss">
.message-user {
  height: fit-content;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  letter-spacing: 0.5px;

  color: #333333;
  padding: 16px;
}

.message-assistant {
  height: fit-content;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  letter-spacing: 0.5px;

  color: #333333;
  background: #FFFAE8;
  padding: 16px;
}
.chat .second-select {
  background: #FFFFFF;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 24px;
  width: 100%;
  height: fit-content;
  color: #B1B5C6;
  border: 1px solid #B1B5C6;
}

.chat .second-select .selector {
  height: fit-content;
  padding: 12px;
}

.chat .second-select.active {
  color: #1694D0;
  border: 1px solid #1694D0;
}

.hidden{
  display: none;
}

</style>
